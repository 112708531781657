import { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../Styles/main.css";
import "../Styles/index.css";
import Nav from "../components/Nav";
import Logo from "../Images/bonsaiSocIcon.png";
function About() {
  const handleScroll = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const blurAmount = Math.min(scrollPosition / 1.4, 50);

    const blurElement = document.getElementById("blur-overlay");
    if (blurElement) {
      blurElement.style.backdropFilter = `blur(${blurAmount}px)`;
      blurElement.style.webkitBackdropFilter = `blur(${blurAmount}px)`; // For Safari
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Southend Bonsai Society | About Us</title>
        <meta
          name="description"
          content="Learn more about the Southend Bonsai Society, its history, and mission."
        />
        <meta
          name="keywords"
          content="Southend Bonsai Society, bonsai history, bonsai mission"
        />
        <meta
          property="og:title"
          content="Southend Bonsai Society | About Us"
        />
        <meta
          property="og:description"
          content="Learn more about the Southend Bonsai Society, its history, and mission."
        />
      </Helmet>
      <link rel="stylesheet" href="/public/Styles/main.css" />
      <link rel="stylesheet" href="/public/Styles/index.css" />
      <link rel="icon" href="/public/Images/bonsaiSocIcon.ico" />
      <Nav />
      <title>Southed Bonsai - Our History</title>
      <div id="content-wrapper">
        <div id="blur-overlay">
          <div id="main">
            <div id="center">
              <div className="content-container">
                <h1>Our History</h1>
                <img className="icon" alt="Icon" src={Logo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
